// :root {
//   --theme-color: '#000000';
// }

// $theme-colors: (
//     "primary": teal
// );

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
// $primary: var(--theme-color);
$primary: rebeccapurple;
@import "~bootstrap/scss/bootstrap";

:root {
  --theme-color: white;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  /*background-color: #282c34;*/
  
}

html {
  background-color: var(--theme-color);
}

.theme-dark {
  background-color: black;
  color: white;
}
.theme-dark table {
  color: white;
}
.theme-dark .offcanvas {
  background-color: black;
  color: white;
}
.theme-dark .btn-close {
  color: white;
}
a {
  text-decoration: none;
  color: var(--theme-color);
}

a:hover, a:focus {
  text-decoration: none;
  color: var(--theme-color-darken1);
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--theme-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25);
}
.form-select:focus {
  border-color: var(--theme-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  text-align: left;
}

pre {
  max-width: 100vw;
  // white-space: unset;
  // text-align: left;
}

.App-loader {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--theme-color);
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--theme-color);
  min-height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header h1 a {
  color: white;
  text-decoration: none;
}
.theme-dark .App-header h1,
.theme-dark .App-header h1 a {
  color: black;
}
.App-nav {
  background-color: #EEE;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.App-nav a {
  color: var(--theme-color-darken1);
  text-decoration: none;
}
nav.hamburger {
  position: absolute;
  top: 10px;
  left: 15px;
}
nav.hamburger span, th[data-field] {
  cursor: pointer;
}
.theme-dark nav.hamburger {
  color: black
}
.App-section {
  /*background-color: #282c34;*/
  min-height: calc(100vh - 110px);
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /*font-size: calc(10px + 2vmin);
  color: white;*/
  padding: 1em;
  max-width: 1400px;
  margin: auto;
}

@media (max-width: 575px) {
  .App-header {
    min-height: 80px;
  }
  .App-section {
    min-height: calc(100vh - 80px);
  }
}



h2 {
  // margin-top: .5em;
  // margin-bottom: 1em;
  font-size: 2em;
  color: var(--theme-color);
  margin: 0 0 .2em;
  text-align: left;
}
.breadcrumb {
  margin: 0;
}
table {
  text-align: left;
  /*border-colo\r: #eee;*/
}
th {
  font-weight: 300;
}
th.active {
  font-weight: 500;
}

th.active i {
  font-weight: 300;
}
.toast-body {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.bg-primary {
  // --bs-bg-opacity: 1;
  background-color: var(--theme-color) !important;
  }

  .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
  }

.theme-dark .btn-primary, .theme-dark .btn-secondary,
.theme-dark .btn-primary:hover, .theme-dark .btn-secondary:hover, .theme-dark .btn-outline-secondary:hover {
  color: black;
}

a.text-theme {
  color: black;
  text-decoration: none;
}
.theme-dark a.text-theme {
  color: white;
}
a.text-theme:hover, a.text-theme:active  {
  text-decoration: underline;
}

.offcanvas {
  --bs-offcanvas-width: 280px;
}

.nowrap th, .nowrap td, .nowrap {
  white-space: nowrap;
}

.App-section-header {
  border-bottom: 1px solid var(--theme-color);
  padding-bottom: 1em;
}

.picture-wrapper {
  width: 40px;
  height: 40px;
  background-color: white;
  line-height: 40px;
  border-radius: 2px;
}
@media (max-width: 575px) {
  .App-header {
    min-height: 80px;
  }
  .App-section {
    min-height: calc(100vh - 80px);
  }
  .offcanvas {
    --bs-offcanvas-width: 100vw;
  }
}

.border-top {
  --bs-border-color: var(--theme-color);
}


// Tabs
.tab-content {
  text-align: left;
}
// Forms
form {
  text-align: left;
}
.form-label {
  font-weight: 300;
}
.theme-dark .accordion {
    --bs-accordion-btn-color: var(--theme-color);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-accordion-btn-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%235c2e8a%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }
// Accordions
.theme-dark .accordion-item {
  --bs-accordion-bg: #000;
  --bs-accordion-border-color: var(--theme-color);
}
.theme-dark .accordion-button {
  --bs-accordion-btn-bg: #000;
  --bs-accordion-active-bg: #000;
  --bs-accordion-btn-color: var(--theme-color);
  --bs-accordion-active-color: var(--theme-color);
  box-shadow: none;
}

.theme-dark  .accordion-button:not(.collapsed)::after {
  background-image: none;
  // transform: var(--bs-accordion-btn-icon-transform);
}
.accordion .table {
  margin-bottom: 0;
}

.nav-link {
  // font-size: .85em;
}

@media (max-width: 575px) {
  h2 {
    text-align: center;
  }
  .breadcrumb {
    justify-content: center;
  }
  .btn-group > .btn, .btn-group-vertical > .btn {
    font-size: .8rem;
  }
  .nav-pills {
    flex-direction: column;
  }
}

.text-blue {
  color: var(--bs-blue);
}
.text-purple {
  color: var(--bs-purple);
}
.text-green {
  color: var(--bs-green);
}
.text-orange {
  color: var(--bs-orange);
}
.text-yellow {
  color: var(--bs-yellow);
}
.text-red {
  color: var(--bs-red);
}
.text-pink {
  color: var(--bs-pink);
}
.text-indigo {
  color: var(--bs-indigo);
}
.text-theme {
  color: var(--theme-color);
}

[class^="icon-wh"]:before,
[class*=" icon-wh"]:before {
  width: 1.2em;
  line-height: 1.2em;
  font-size: 1.2em;
  vertical-align: bottom;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--theme-color);
  color: black
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--theme-color);
  --bs-btn-border-color: var(--theme-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--theme-color-darken1);
  --bs-btn-hover-border-color: var(--theme-color-darken2);
  --bs-btn-focus-shadow-rgb: 125, 82, 168;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--theme-color-darken2);
  --bs-btn-active-border-color: var(--theme-color-darken3);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--theme-color);
  --bs-btn-disabled-border-color: var(--theme-color);
}

.btn-outline-primary {
	--bs-btn-color: var(--theme-color);
	--bs-btn-border-color: var(--theme-color);
	--bs-btn-hover-color: #000;
	--bs-btn-hover-bg: var(--theme-color);
	--bs-btn-hover-border-color: var(--theme-color);
	--bs-btn-focus-shadow-rgb: 102, 51, 153;
	--bs-btn-active-color: #000;
	--bs-btn-active-bg: var(--theme-color);
	--bs-btn-active-border-color: var(--theme-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--theme-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--theme-color);
	--bs-gradient: none;
}